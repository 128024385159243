import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import YoutubeIcon from '../../../../components/icons/YoutubeIcon';

import Breadcrumbs from '../../../../components/Breadcrumbs';
import Link from '../../../../components/Link';
import Seo from '../../../../components/Seo';

const PisniKhronikyPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "pisni-khroniky"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Пісні-хроніки"
        description="Пісні-хроніки — є літературним жанром, який відображає неординарні, виняткові події громадсько-побутового та родинно-побутового характеру."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Пісні-хроніки
          </h1>
          <p className="typo-body mt-4 max-w-3xl">
            Пісні-хроніки — є літературним жанром, який відображає неординарні, виняткові події громадсько-побутового та родинно-побутового характеру, цей
            літературний жанр описує реальні факти, з усіма подробицями інформуючи про особливе в житті людини або колективу.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список пісень-хронік
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру пісні-хроніки
        </h2>
        <p className="typo-body mt-4">
        Пісні-хроніки занадто схожі за жанром до історичних пісень і до балад, про які детальніше говоритиметься на
        наступних сторінках. З історичними піснями хроніки поєднує дійсність описаного, правдиві факти, закладені в їх
        основу, але, зазвичай, пісні-хроніки лімітуються зображенням якогось одного випадку, частіше всього трагічного,
        свідком або й учасником якого був співак. Якщо прирівнювати історичні пісні до картин, змальовані великими
        яскравими мазками олійних фарб, то пісні-хроніки можна порівняти з детальною промальовкою олівцем. Крім того,
        пісні-хроніки легко впізнати за чисто формальними ознаками: майже кожна з них починається звертанням до
        слухачів: “послухайте, добрі люди, що маю казати, а я хочу про (ім’я) співанку співати”, а закінчується
        заповненням у цілковитій правдивості співаного:
        </p>
        <p className="typo-body italic mt-4">
          Сіло котя на воротях, на вітер си здуло,
          <br />
          Я би того не співала, якби так не було.
        </p>
        <p className="typo-body mt-4">
          Матеріалом для пісень-хронік найчастіше служать випадки місцевого, чисто побутового характеру, але у свою
          орбіту вони можуть втягати і зображення подій громадсько-політичного значення. Тільки й тут не обходиться без
          фотографічно точного показу якогось одного епізоду. Наприклад, про початок революційного виступу селян одного
          з сіл Західної України сказано так:
        </p>
        <p className="typo-body italic mt-4">
          У неділю, дуже рано, як ніхто не бачив,
          <br />
          На Прокоповій тополі прапор замаячив.
        </p>
        <p className="typo-body mt-4">
          Хіба вам не здається, що той прапор повісив або сам співак, або той, хто бачив усе, що діялося, бо він не каже
          "на дереві", а саме "на тополі"?
        </p>
        <p className="typo-body mt-4">
          Пісні-хроніки відомі усім слов'янським народам, однак майже ніхто з дослідників епічної пісенності не розглядав
          їх як окремий жанр. Дослідник О. Дей був першим, хто це зробив. Він відзначив, що описово-хронікальні епічні пісні
          функціонували в Карпатському регіоні, в Галичині і на Поділлі, а також на Західній Волині ще у XVIII ст.
          Фольклорист дав їм назву "співанки-хроніки", бо в цих регіонах термін "співанка" вживається частіше, ніж
          "пісня". О. Дей наголошує також на такій особливості пісень-хронік, як вказівка на авторство у текстах, хоча
          самі тексти складено у традиційно-фольклорному стилі:
        </p>
        <p className="typo-body italic mt-4">
          А хто вміє заспівати, то най заспіває,
          <br />
          Ступареві співаночки най не забуває.
        </p>
        <p className="typo-body mt-4">
          (Ступар - прізвище автора, який "віддає" свій твір кожному, "хто вміє заспівати" - це суто фольклорна
          особливість.- Н. Ш.)
        </p>
        <p className="typo-body mt-4">
          Найактивніше мотиви пісень-хронік використовували у своїй творчості Юрій Федькович (поема "Довбуш") та Іван
          Франко (драма "Украдене щастя", сатира "Звірячий парламент", вірші "Жінка з револьвером") тощо.
        </p>
      </div>
    </>
  );
};

export default PisniKhronikyPage;
